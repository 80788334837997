// import 'bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'jquery';
// import 'popper.js';
// import '../css/main.css'
import '../css/iconfont.css'
require('../scss/main.scss');

$(function() {

	console.log('执行环境：' + process.env.NODE_ENV);
	console.log('jquery版本：' + $.fn.jquery);

	if (window.sessionStorage.getItem('params') == null) {
		let url = new URL(window.location.href);
		if (url.searchParams.size > 0) {
			window.sessionStorage.setItem('params', url.searchParams);
			// $.ajax({
			// 	url: "/pig/invite/record/" + url.searchParams.values().next().value,
			// 	type: "POST",
			// 	data: JSON.stringify({}),
			// 	contentType: "application/json; charset=utf-8",
			// 	dataType: "json",
			// 	success: function(res) {
			// 		console.log(res);
			// 	}
			// })
		}
	}
	console.log('获取到参数：' + window.sessionStorage.getItem('params'));

	$(".go-login").click(function() {
		if (navigator.userAgent.match(/mobile/i)) {
			let url = "https://ai.jwin100.cn/login";
			if (window.sessionStorage.getItem('params') != null) {
				url = url + window.sessionStorage.getItem('params');
			}
			window.open(url);
		} else {
			let url = "https://ai.jwin100.cn/login";
			if (window.sessionStorage.getItem('params') != null) {
				url = url + window.sessionStorage.getItem('params');
			}
			window.open(url);
		}
	});

	$(".sign-append-navbar").click(function() {
		if (window.sessionStorage.getItem('params') != null) {
			let url = $(".sign-append-navbar").attr("href");
			if (url.indexOf("&") == -1) {
				url = url + "&" + window.sessionStorage.getItem('params');
				$(".sign-append-navbar").attr("href", url);
			}
		}
	});

	$(".sign-append-banner").click(function() {
		if (window.sessionStorage.getItem('params') != null) {
			let url = $(".sign-append-banner").attr("href");
			if (url.indexOf("&") == -1) {
				url = url + "&" + window.sessionStorage.getItem('params');
				$(".sign-append-banner").attr("href", url);
			}
		}
	});

	$(".sign-append-inviter").click(function() {
		if (window.sessionStorage.getItem('params') != null) {
			let url = $(".sign-append-inviter").attr("href");
			if (url.indexOf("&") == -1) {
				url = url + "&" + window.sessionStorage.getItem('params');
				$(".sign-append-inviter").attr("href", url);
			}
		}
	});

	$(".widget-phone-show").click(function() {
		if (navigator.userAgent.match(/mobile/i)) {
			window.open('tel:18217071490')
		}
	});

	//首先将#back-to-top隐藏
	$(".widget-totop").css('visibility', 'hidden');
	//当滚动条的位置处于距顶部100像素以下时，跳转链接出现，否则消失
	$(window).scroll(function() {
		if ($(window).scrollTop() > 100) {
			$(".widget-totop").css('visibility', 'visible');
		} else {
			$(".widget-totop").css('visibility', 'hidden');
		}
	});
	//当点击跳转链接后，回到页面顶部位置
	$(".widget-totop").click(function() {
		$('body,html').animate({
			scrollTop: 0
		}, 500);
		return false;
	});

	//百度统计（只在生产环境加载）
	// if (process.env.NODE_ENV === 'production') {
	// 	var _hmt = _hmt || [];
	// 	(function() {
	// 		var hm = document.createElement("script");
	// 		hm.src = "https://hm.baidu.com/hm.js?ab23ad9ad1d8ba11fc6dddcad7957fa1";
	// 		var s = document.getElementsByTagName("script")[0];
	// 		s.parentNode.insertBefore(hm, s);
	// 	})();
	// 	console.log("百度统计加载完成");
	// }
});
